<template>
  <footer>
    <div class="bg-primary text-white px-lg-4 py-3 py-lg-5">
      <div class="container-fluid gx-0 gx-lg-3">
        <div class="row gx-0 gx-lg-3">
          <div class="col-12 col-lg d-flex justify-content-center">
            <div class="d-flex flex-row flex-lg-column align-items-center mb-4">
              <router-link
                to="/"
                class="w-100 d-block mb-2 me-3 me-lg-0 text-white"
              >
                <img
                  v-if="footer.logo.src"
                  class="d-block object-fit-contain mx-auto mb-lg-3"
                  :src="footer.logo.src"
                  :alt="footer.logo.alt"
                  :class="$style.logo"
                >
              </router-link>
              <div class="d-flex align-items-center">
                <a
                  v-for="(item, index) in footerSocial"
                  :key="index"
                  :href="item.href"
                  class="text-white"
                  target="_blank"
                >
                  <AppIcon
                    :name="`social-${item.name.toLowerCase() === 'ig' ? 'instagram' : item.name.toLowerCase()}`"
                    class="mx-1"
                    :class="$style.icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <h4
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowContact"
            >
              聯絡資訊
              <AppIcon
                v-if="showContact"
                name="minus"
                class="icon-sm"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-sm"
              />
            </h4>
            <div
              v-if="!showContact"
              class="mx-3 border-bottom"
            />
            <transition name="collapse">
              <div
                v-if="showContact"
                class="bg-white text-dark"
              >
                <p class="p-3">
                  地址：{{ footer.address }}<br>
                  電話：<a
                    :href="`tel:${footer.phone}`"
                    class="text-dark"
                  >{{ footer.phone }}</a><br>
                  <!-- 傳真：{{ footer.serviceTime }}<br> -->
                  Email：<a
                    :href="`mailto:${footer.email}`"
                    class="text-dark"
                  >{{ footer.email }}</a><br>
                  服務時間：{{ footer.serviceTime }}
                </p>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="d-none d-lg-block col-lg">
            <div class="mb-4">
              <h4>
                地址：
              </h4>
              <p>{{ footer.address }}</p>
            </div>
            <div class="mb-4">
              <h4>
                電話：
              </h4>
              <a
                :href="`tel:${footer.phone}`"
                class="text-white"
              >{{ footer.phone }}</a>
            </div>
            <!-- <div>
              <h4>
                傳真：
              </h4>
              <p>{{ footer.serviceTime }}</p>
            </div> -->
          </div>
          <div class="d-none d-lg-block col-lg">
            <div class="mb-4">
              <h4>
                Email：
              </h4>
              <a
                :href="`mailto:${footer.email}`"
                class="text-white"
              >{{ footer.email }}</a>
            </div>
            <div>
              <h4>
                服務時間：
              </h4>
              <p>{{ footer.serviceTime }}</p>
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none col-12">
            <h4
              role="button"
              class="d-flex align-items-center justify-content-between p-3"
              @click="setShowInfo"
            >
              {{ footer.customPage.title }}
              <AppIcon
                v-if="showInfo"
                name="minus"
                class="icon-sm"
              />
              <AppIcon
                v-else
                name="plus"
                class="icon-sm"
              />
            </h4>
            <div
              v-if="!showInfo"
              class="mx-3 border-bottom"
            />
            <transition name="collapse">
              <div v-if="showInfo">
                <div class="d-flex flex-column bg-white text-dark p-3">
                  <a
                    v-for="(item, index) in footer.customPage.page"
                    :key="index"
                    :href="item.href"
                    :class="$style.link"
                  >{{ item.name }}</a>
                </div>
              </div>
            </transition>
          </div>
          <!-- computer -->
          <div class="d-none d-lg-block col-lg d-flex flex-column">
            <h4>{{ footer.customPage.title }}</h4>
            <a
              v-for="(item, index) in footer.customPage.page"
              :key="index"
              :href="item.href"
              class="d-block"
              :class="$style.link"
            >{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between px-3 py-2">
      <small>{{ footer.copyright }}</small>
      <small>Design by 沃知</small>
    </div>
    <div
      id="FaceBookMsg"
      v-html="facebookBodyCode"
    />
  </footer>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, nextTick } from 'vue-demi'
export default {
  name: 'TheFooter',
  async setup () {
    const { dispatch, getters } = useStore()

    const footer = computed(() => getters.getFooter)

    const footerSocial = computed(() => {
      if (footer.value.social && footer.value.social.length > 0) {
        return footer.value.social.filter((item) => item.href && item.href !== 'https://')
      } else {
        return []
      }
    })

    const showContact = ref(false)
    const setShowContact = () => {
      showContact.value = !showContact.value
    }

    const showInfo = ref(false)
    const setShowInfo = () => {
      showInfo.value = !showInfo.value
    }

    const facebookBodyCode = ref(null)
    const addFacebookScript = (facebookCode) => {
      const bodyCode = facebookCode.replaceAll(/<script>.*<\/script>/g, '')
      const scriptCode = facebookCode.match(/<script>.*<\/script>/)[0].replaceAll(/<script>/g, '').replaceAll(/<\/script>/g, '').replaceAll(/<!--.*-->/g, '')

      facebookBodyCode.value = bodyCode
      nextTick(() => {
        const newScript = document.createElement('script')
        const inlineScript = document.createTextNode(scriptCode)
        newScript.appendChild(inlineScript)
        document.getElementById('FaceBookMsg').append(newScript)
      })
    }

    const readFooter = async () => {
      const payload = { }
      try {
        await dispatch('readFooter', { payload })

        nextTick(() => {
          if (document.getElementById('FaceBookMsg')) {
            addFacebookScript(footer.value.FaceBookMsg)
          } else {
            setTimeout(() => {
              addFacebookScript(footer.value.FaceBookMsg)
            }, 500)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    await readFooter()

    return {
      footerSocial,
      facebookBodyCode,
      footer,
      showContact,
      setShowContact,
      showInfo,
      setShowInfo
    }
  }
}
</script>

<style lang="scss" module>
:global(.collapse-enter-active),
:global(.collapse-leave-active) {
  max-height: 100vh;
  transition: max-height 0.3s ease;
}

:global(.collapse-enter-from),
:global(.collapse-leave-to) {
  overflow: hidden;
  max-height: 0;
}

$icon-size: 30px;

.icon {
  width: $icon-size;
  height: $icon-size;
  fill: currentColor;
}

.logo {
  width: auto;
  height: 100px;
}

.link {
  color: $dark;
  transition: all 0.3s;

  &:hover {
    color: $dark;
    opacity: 0.6;
  }
}

@include media-breakpoint-up(lg) {
  .link {
    color: $white;
    transition: all 0.3s;

    &:hover {
      color: $white;
      opacity: 0.6;
    }
  }
}
</style>
