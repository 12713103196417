<template>
  <metainfo />
  <div class="position-relative">
    <div>
      <div
        class="flex-grow-1"
        :class="$style.main"
      >
        <RouterView v-slot="{ Component }">
          <template v-if="Component">
            <Suspense>
              <component :is="Component" />
            </Suspense>
          </template>
        </RouterView>
      </div>
      <suspense>
        <TheNav class="flex-grow-0" />
      </suspense>
    </div>
    <div
      class="w-100 position-sticky bottom-0"
      :class="$style.boxShadow"
    >
      <router-link
        to="/quotation"
        class="w-100 d-flex d-lg-none align-items-center justify-content-center bg-primary text-white py-3"
      >
        詢價單
        <div
          :class="$style.messageNumber"
          class="ms-2"
        >
          {{ quotation.length }}
        </div>
      </router-link>
    </div>
  </div>
  <suspense>
    <TheFooter :class="$style.main" />
  </suspense>
</template>

<script>
import TheNav from '@/components/TheNav.vue'
import TheFooter from '@/components/TheFooter.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue-demi'
import { useStore } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    TheNav, TheFooter
  },
  setup () {
    const { getters } = useStore()
    const router = useRouter()

    router.beforeEach((to, from, next) => {
      scrollTop()
      next()
    })

    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const quotation = computed(() => getters.getQuotation)

    return {
      quotation
    }
  }

}
</script>

<style lang="scss" module>
.main {
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .main {
    width: calc(100% - $nav-width);
  }
}

.messageNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  background-color: $danger;
  border-radius: 50%;
}

.boxShadow {
  z-index: 10;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
}
</style>
