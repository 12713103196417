import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { useAPI } from '@/helpers/useApi'

import Cookies from 'js-cookie'

export default createStore({
  state: {
    quotation: [],

    about: {},
    contact: {},
    cookiePolicy: {},
    disclaimer: {},
    download: {},
    footer: {},
    header: {},
    index: {},
    newsdetail: {},
    newslist: {},
    photobookdetail: {},
    photobooklist: {},
    privacyPolicy: {},
    productdetail: {},
    productdetailpreview: {},
    productlist: {},
    productrfq: {},
    productsearch: {},
    qalist: {},
    service: {}
  },
  getters: {
    getQuotation: state => state.quotation,

    getAbout: state => state.about,
    getContact: state => state.contact,
    getCookiePolicy: state => state.cookiePolicy,
    getDisclaimer: state => state.disclaimer,
    getDownload: state => state.download,
    getFooter: state => state.footer,
    getHeader: state => state.header,
    getIndex: state => state.index,
    getNewsdetail: state => state.newsdetail,
    getNewslist: state => state.newslist,
    getPhotobookdetail: state => state.photobookdetail,
    getPhotobooklist: state => state.photobooklist,
    getPrivacyPolicy: state => state.privacyPolicy,
    getProductdetail: state => state.productdetail,
    getProductdetailpreview: state => state.productdetailpreview,
    getProductlist: state => state.productlist,
    getProductrfq: state => state.productrfq,
    getProductsearch: state => state.productsearch,
    getQalist: state => state.qalist,
    getService: state => state.service
  },
  mutations: {
    setQuotation: (state, { data, insert = false }) => {
      if (insert) {
        state.quotation.push(data)
      } else {
        state.quotation = data
      }
    },

    setAbout: (state, { data }) => { state.about = data },
    setContact: (state, { data }) => { state.contact = data },
    setCookiePolicy: (state, { data }) => { state.cookiePolicy = data },
    setDisclaimer: (state, { data }) => { state.disclaimer = data },
    setDownload: (state, { data }) => { state.download = data },
    setFooter: (state, { data }) => { state.footer = data },
    setHeader: (state, { data }) => { state.header = data },
    setIndex: (state, { data }) => { state.index = data },
    setNewsdetail: (state, { data }) => { state.newsdetail = data },
    setNewslist: (state, { data }) => { state.newslist = data },
    setPhotobookdetail: (state, { data }) => { state.photobookdetail = data },
    setPhotobooklist: (state, { data }) => { state.photobooklist = data },
    setPrivacyPolicy: (state, { data }) => { state.privacyPolicy = data },
    setProductdetail: (state, { data }) => { state.productdetail = data },
    setProductdetailpreview: (state, { data }) => { state.productdetailpreview = data },
    setProductlist: (state, { data }) => { state.productlist = data },
    setProductrfq: (state, { data }) => { state.productrfq = data },
    setProductsearch: (state, { data }) => { state.productsearch = data },
    setQalist: (state, { data }) => { state.qalist = data },
    setService: (state, { data }) => { state.service = data }
  },
  actions: {
    readAbout: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_about.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setAbout', { data: responseData })
        return response
      } catch (error) {
        commit('setAbout', { data: {} })
        return Promise.reject(error)
      }
    },
    readContact: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_contact.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setContact', { data: responseData })
        return response
      } catch (error) {
        commit('setContact', { data: {} })
        return Promise.reject(error)
      }
    },
    readCookiePolicy: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_cookiePolicy.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setCookiePolicy', { data: responseData })
        return response
      } catch (error) {
        commit('setCookiePolicy', { data: {} })
        return Promise.reject(error)
      }
    },
    readDisclaimer: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_disclaimer.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setDisclaimer', { data: responseData })
        return response
      } catch (error) {
        commit('setDisclaimer', { data: {} })
        return Promise.reject(error)
      }
    },
    readDownload: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_download.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setDownload', { data: responseData })
        return response
      } catch (error) {
        commit('setDownload', { data: {} })
        return Promise.reject(error)
      }
    },
    readFooter: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_footer.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setFooter', { data: responseData })
        return response
      } catch (error) {
        commit('setFooter', { data: {} })
        return Promise.reject(error)
      }
    },
    readHeader: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_header.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setHeader', { data: responseData })
        return response
      } catch (error) {
        commit('setHeader', { data: {} })
        return Promise.reject(error)
      }
    },
    readIndex: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_index.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setIndex', { data: responseData })
        return response
      } catch (error) {
        commit('setIndex', { data: {} })
        return Promise.reject(error)
      }
    },
    readNewsdetail: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_newsdetail.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setNewsdetail', { data: responseData })
        return response
      } catch (error) {
        commit('setNewsdetail', { data: {} })
        return Promise.reject(error)
      }
    },
    readNewslist: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_newslist.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setNewslist', { data: responseData })
        return response
      } catch (error) {
        commit('setNewslist', { data: {} })
        return Promise.reject(error)
      }
    },
    readPhotobookdetail: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_photobookdetail.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setPhotobookdetail', { data: responseData })
        return response
      } catch (error) {
        commit('setPhotobookdetail', { data: {} })
        return Promise.reject(error)
      }
    },
    readPhotobooklist: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_photobooklist.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setPhotobooklist', { data: responseData })
        return response
      } catch (error) {
        commit('setPhotobooklist', { data: {} })
        return Promise.reject(error)
      }
    },
    readPrivacyPolicy: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_privacyPolicy.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setPrivacyPolicy', { data: responseData })
        return response
      } catch (error) {
        commit('setPrivacyPolicy', { data: {} })
        return Promise.reject(error)
      }
    },
    readProductdetail: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_productdetail.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setProductdetail', { data: responseData })
        return response
      } catch (error) {
        commit('setProductdetail', { data: {} })
        return Promise.reject(error)
      }
    },
    readProductdetailpreview: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_productdetail.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setProductdetailpreview', { data: responseData })
        return response
      } catch (error) {
        commit('setProductdetailpreview', { data: {} })
        return Promise.reject(error)
      }
    },
    readProductlist: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_productlist.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setProductlist', { data: responseData })
        return response
      } catch (error) {
        commit('setProductlist', { data: {} })
        return Promise.reject(error)
      }
    },
    readProductrfq: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_productrfq.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setProductrfq', { data: responseData === 'nothing' ? {} : responseData })
        return response
      } catch (error) {
        commit('setProductrfq', { data: {} })
        return Promise.reject(error)
      }
    },
    readProductsearch: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_productsearch.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setProductsearch', { data: responseData })
        return response
      } catch (error) {
        commit('setProductsearch', { data: {} })
        return Promise.reject(error)
      }
    },
    readQalist: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_qalist.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setQalist', { data: responseData })
        return response
      } catch (error) {
        commit('setQalist', { data: {} })
        return Promise.reject(error)
      }
    },
    readService: async ({ commit }, { payload }) => {
      const method = 'get'
      const path = '/api_service.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setService', { data: responseData })
        return response
      } catch (error) {
        commit('setService', { data: {} })
        return Promise.reject(error)
      }
    },
    createQuotation: async ({ commit }, { payload }) => {
      const method = 'post'
      const path = '/api_rfq.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        commit('setQuotation', { data: [] })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createContactUs: async ({ commit }, { payload }) => {
      const method = 'post'
      const path = '/api_contactus.php'
      const data = { ...payload }

      try {
        const response = await useAPI({ method, path, data })
        const responseData = response.data
        console.log(responseData)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  plugins: [
    createPersistedState({
      key: '_State_',
      storage: {
        getItem: (key) => {
          Cookies.get(key)
        },
        setItem: (key, value) => {
          Cookies.set(key, value, { expires: 7, secure: true })
        },
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ]
})
