<template>
  <div>
    <!-- menu -->
    <transition name="menu">
      <div
        v-if="showMenu"
        class="d-flex align-items-start align-items-lg-center justify-content-start justify-content-lg-center position-fixed w-100 h-100 top-0 end-0 bg-white"
        :class="$style.zIndex30"
      >
        <!-- cross -->
        <div
          role="button"
          class="position-fixed top-0 end-0 p-1 pt-2 m-4 d-flex d-lg-none flex-column align-items-center justify-content-center bg-white rounded-circle"
          @click="setShowMenu(false)"
        >
          <AppIcon
            name="cross"
            class="icon"
          />
        </div>
        <!-- menu item -->
        <ul
          class="list-unstyled d-flex flex-column"
          :class="$style.menuList"
        >
          <router-link
            v-for="(item, index) in header.menu"
            :key="index"
            :to="item.href"
            class="p-3 h3"
            :class="$style.menuItem"
          >
            {{ item.text }}
          </router-link>
        </ul>
      </div>
    </transition>
    <!-- 手機板選單 -->
    <div
      role="button"
      class="position-fixed top-0 end-0 m-3 d-flex d-lg-none flex-column align-items-center justify-content-center bg-white rounded-circle"
      :class="$style.hamburgerMobile"
      @click="setShowMenu()"
    >
      <div class="d-flex flex-column align-items-start">
        <AppIcon
          name="hamburger"
          class="icon-sm"
        />
        <small>選單</small>
      </div>
    </div>
    <!-- 電腦版選單 -->
    <nav
      class="position-fixed top-0 end-0 min-vh-100 d-none d-lg-flex flex-column align-items-center justify-content-center bg-white"
      :class="$style.nav"
    >
      <div class="d-flex flex-column align-items-start">
        <div
          v-if="showMenu"
          role="button"
          class="d-flex flex-column align-items-center"
          :class="[$style.menuButton, $style.link]"
          @click="setShowMenu(false)"
        >
          <AppIcon
            name="cross"
            class="icon mt-2"
          />
        </div>
        <div
          v-else
          role="button"
          class="d-flex flex-column align-items-start"
          :class="[$style.menuButton, $style.link]"
          @click="setShowMenu(true)"
        >
          <AppIcon
            name="hamburger"
            class="icon-lg"
          />
          <span>選單</span>
        </div>
        <!-- quotation item number -->
        <router-link
          to="/quotation"
          class="d-block mb-4"
          :class="$style.link"
        >
          <AppIcon
            name="message-mark"
            class="icon"
          />
          <div :class="$style.messageNumber">
            {{ quotation.length }}
          </div>
        </router-link>
        <!-- phone -->
        <a
          :href="`tel:${header.phone}`"
          class="d-block mb-4"
          :class="$style.link"
        >
          <AppIcon
            name="phone"
            class="icon"
          />
        </a>
        <!-- mail -->
        <a
          :href="`mailto:${header.email}`"
          class="d-block mb-4"
          :class="$style.link"
        >
          <AppIcon
            name="mail"
            class="icon"
          />
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { computed } from 'vue-demi'
import { useStore } from 'vuex'
export default {
  name: 'TheNav',
  async  setup () {
    const { dispatch, getters } = useStore()
    const router = useRouter()

    router.beforeEach((to, from, next) => {
      setShowMenu(false)
      next()
    })
    const showMenu = ref(false)
    const setShowMenu = (active) => {
      showMenu.value = typeof active === 'boolean' ? active : !showMenu.value
    }

    const header = computed(() => getters.getHeader)
    const readHeader = async () => {
      const payload = { }
      try {
        await dispatch('readHeader', { payload })
      } catch (error) {
        console.log(error)
      }
    }

    const quotation = computed(() => getters.getQuotation)

    await readHeader()

    return { showMenu, setShowMenu, header, quotation }
  }
}
</script>

<style lang="scss" module>
:global(.menu-enter-active),
:global(.menu-leave-active) {
  transition: transform 0.3s ease;
}

:global(.menu-enter-from),
:global(.menu-leave-to) {
  transform: translate(0%, -100%);
}

.menuList {
  margin: 25% 0 0 10%;
}

@include media-breakpoint-up(md) {
  .menuList {
    margin: 15% 0 0 10%;
  }
}

@include media-breakpoint-up(lg) {
  :global(.menu-enter-from),
  :global(.menu-leave-to) {
    transform: translate(100%, 0%);
  }

  .menuList {
    margin: -15% 0 0;
    writing-mode: vertical-rl;
  }

  .menuItem {
    letter-spacing: 8px;
  }
}

.zIndex30 {
  z-index: 30;
}

.hamburgerMobile {
  z-index: 20;
  width: 3.375rem;
  height: 3.375rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.nav {
  z-index: 40;
  width: $nav-width;
  border-left: 1px solid $light;
}

.menuButton {
  margin-bottom: 6rem;
  width: 40px;
  height: 56px;
}

.link {
  position: relative;
  color: $gray;
  transition: all 0.3s;

  &:hover {
    color: $primary;
  }
}

.messageNumber {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(70%, -25%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  background-color: $danger;
  border-radius: 50%;
}
</style>
