import axios from 'axios'

const api = axios.create({
  baseURL: window.location.href.includes('https') ? 'https://www.song-you.com/api' : 'http://103.17.8.152/api',
  'Content-Type': 'application/json'
})

const requestInterceptors = (request) => {
  return request
}

api.interceptors.request.use(
  request => requestInterceptors(request),
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

const dataHandler = (method, data) => {
  return method === 'get' || method === 'delete' ? { params: data } : data
}

const useAPI = ({ method, path, data }) => api[method](`${path}`, dataHandler(method, data))

export {
  useAPI
}
