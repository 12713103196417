import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/not-found',
    name: 'notFound',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('../views/NewsList.vue'),
    props: route => ({
      typeid: route.query.typeid
    })
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/NewsDetail.vue'),
    props: route => ({
      id: route.query.id
    })
  },
  {
    path: '/photobookList',
    name: 'photobookList',
    component: () => import('../views/PhotobookList.vue'),
    props: route => ({
      typeid: route.query.typeid
    })
  },
  {
    path: '/photobookDetail',
    name: 'photobookDetail',
    component: () => import('../views/PhotobookDetail.vue'),
    props: route => ({
      id: route.query.id
    })
  },
  {
    path: '/QAList',
    name: 'QAList',
    component: () => import('../views/QAList.vue')
  },
  {
    path: '/downloadList',
    name: 'downloadList',
    component: () => import('../views/DownloadList.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    component: () => import('../views/ProductList.vue'),
    props: route => ({
      typeid: route.query.typeid
    })
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('../views/ProductDetail.vue'),
    props: route => ({
      id: route.query.id
    })
  },
  {
    path: '/quotation',
    name: 'quotation',
    component: () => import('../views/QuotationView.vue')
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: () => import('../views/CookieView.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('../views/DisclaimerView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/HomeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
